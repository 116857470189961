import { t } from "i18next";
import useFetch from "../hooks/useFetch";
import { ShoppingCartPlus } from "tabler-icons-react";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import { Loader, Modal } from "@mantine/core";
import ModalData from "./ModalData";
import { useTranslation } from "react-i18next";
import { Notification } from "@mantine/core";

const Products = ({ route, page, header }) => {
  const [nextPage, setNextPage] = useState(parseInt(page));
  const [loadingMore, setLoadingMore] = useState(false);

  const { data: products, statusCode, isPending } = useFetch(route, nextPage);
  const [modalData, setModalData] = useState(null);
  const [opened, setOpened] = useState(false);
  const { i18n } = useTranslation();
  const [notification, setNotification] = useState(false);

  const openModal = (e, index) => {
    setModalData(products[index]);
    setOpened(true);
  };
  //to start with page 0 every new route
  useEffect(() => {
    setNextPage(0);
  }, [route]);
  useEffect(() => {
    setTimeout(() => {
      setNotification(false);
    }, 4000);
  }, [notification]);
  //return loading more to false after products loads
  useEffect(() => {
    setLoadingMore(false);
  }, [products]);
  const getMoreProducts = () => {
    setLoadingMore(true); //to prevent hiding products while loading more
    setNextPage(nextPage + 1);
  };

  return (
    <div className="products-section mt-4 ">
      {!isPending || loadingMore ? (
        <>
        {!products ? <div className="my-5"><span className="text-capitalize text-center text-secondary">{t('products.noresults')} {header}</span></div>     :    <h2 className="text-capitalize text-center text-primary-dark">{header}</h2>
 }
          <div className="row gx-lg-5 gx-md-5 gx-4">
            {products &&
              products.map((product, index) => (
                <div
                  className={`product-div  col-lg-3 col-md-6 col-6  mt-3`}
                  onClick={(e) => openModal(e, index)}
                  key={index}
                >
                  <div className="">
                    <div className="product-img-div ">
                      <img
                        className="product-img curser-pointer"
                        src={product.photo[0]}
                        alt=""
                        width={"100%"}
                      />
                    </div>
                    <h5 className="text-center curser-pointer mt-1">
                      {product.name} 
                    </h5>

                    <div className="d-flex justify-content-between">
                      <button
                        className=" btn-secondary-dark rounded-pill px-2"
                        onClick={(e) => openModal(e, index)}
                      >
                      <span className="product-add-to-cart-text">{t("products.addtocart")}{" "}</span>  
                        <ShoppingCartPlus size={22} strokeWidth={1} />
                      </button>

                      <div
                        className="d-flex justify-content-center rounded-pill btn-secondary-dark px-3"
                        dir="ltr"
                      >
                        <h6 className="mx-1 my-1  ">{t("products.jod")}</h6>
                        <h6 className="my-1">{product.price}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {products && (
            <button
              className={`my-4 btn-secondary-dark rounded-pill py-2 w-lg-25 w-sm-50 mx-auto d-block`}
              onClick={getMoreProducts}
              disabled={
                statusCode === 204 || products.length % 12 !== 0 || loadingMore
                  ? true
                  : ""
              }
            >
              {loadingMore && statusCode !== 204 ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                <>
                  {statusCode === 204 || products.length % 12 !== 0
                    ? t("products.nomore")
                    : t("products.showmore")}
                </>
              )}
            </button>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center my-5 w-100">
          <Loader color="red" size="lg" />
        </div>
      )}
      {notification && (
        <Notification
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
          }}
          color="green"
          title={t("notifications.notification")}
          disallowClose
        >
          {t("notifications.addedtocart")}
        </Notification>
      )}
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="BaloonzUp"
        size="90%"
        dir={i18n.language === "ar" ? "rtl" : ""}
      >
        <ModalData
          product={modalData}
          opened={opened}
          onSubmitCart={() => setNotification(true)}
          onClose={() => setOpened(false)}
        />
      </Modal>
    </div>
  );
};

export default Products;

import i18next, { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import useFirebaseGet from "../hooks/useFirebaseGet";
import { Button, Divider } from "@mantine/core";
import Form from "react-bootstrap/Form";
import { Loader } from "@mantine/core";
import { auth, db } from "../firebase";
import { ref, set, remove } from "firebase/database";
import Cookies from "js-cookie";
import axios from "axios";
import invoicePng from "../imgs/invoice.png";
const Checkout = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigateTo = useNavigate();

  const [priceWithTax, setPriceWithTax] = useState(0);
  const [priceWithoutTax, setPriceWithoutTax] = useState(0);
  const [delivery, setDelivery] = useState(0);
  const [service, setService] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [discount, setDiscount] = useState("0");
  const [promoCodeMsg, setPromoCodeMsg] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [branchName, setBranchName] = useState(
    localStorage.getItem("branchName")
  );
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const { data: accounting } = useFetch("accounts");

  const { data: cart } = useFirebaseGet(
    "/Cart/" + localStorage.getItem("branch") + "/" + user.id
  );

  //redirect if came from copied link
  useEffect(() => {
    if (!location.state) {
      navigateTo("/cart");
    } else {
      const branch = location.state.branch;
      setDeliveryAddress(location.state.address);
    }
  }, []);

  useEffect(() => {
    // axios.defaults.withCredentials = true;
    axios.get(process.env.REACT_APP_BACKEND_URL+"/sanctum/csrf-cookie");
  }, []);

  //calculating the total price of the cart and the total price without taxes
  useEffect(() => {
    let cartTotal = 0;
    if (accounting && cart) {
      Object.values(cart).map((product) => {
        cartTotal += parseFloat(JSON.parse(product.total_price));
      });

      setPriceWithTax((cartTotal.toFixed(2) - discount).toFixed(2));
      setPriceWithoutTax((cartTotal / (1 + accounting.tax / 100)).toFixed(2));
      setService((cartTotal * (accounting.fees_value / 100)).toFixed(2));
    }
  }, [accounting, cart, discount]);

  //set Total Price
  useEffect(() => {
    setTotalPrice(
      parseInt(priceWithTax) + parseInt(delivery) + parseInt(service)
    );
  }, [service, delivery, priceWithTax]);
  //set delivery
  useEffect(() => {
    if (typeof branch !== "undefined") {
      setDelivery(parseInt(location.state.address.user_area.price));
      setBranchName(null);
    } else {
      setDeliveryAddress(null);
    }
  }, []);

  const handlePromoCode = async (e) => {
    e.preventDefault();
    setDiscount(0);
    setIsPending(true);

    await axios
      .post(
        process.env.REACT_APP_BACKEND_URL+"/api/dashboard/coupons/discount",
        {
          coupon_code: promoCode,
          phone_number: user.phone,
          "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
        },
        {
          headers: {
            resturant: process.env.REACT_APP_DB_NAME,
            Accept: "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      )
      .then(function (response) {
        if (response.data.data) {
          if (response.data.data.type === "مبلغ") {
            setDiscount(JSON.stringify(response.data.data.value));
          } else if (response.data.data.type === "نسبة") {
            setDiscount(
              ((response.data.data.value / 100) * priceWithoutTax).toFixed(2)
            );
          }
        }
        setPromoCodeMsg(response.data["msg_" + i18next.language]);
        setIsPending(false);
      })
      .catch(function (error) {
        console.log(error, "error ");
      });
  };

  const handleSendOrder = async () => {
    if (auth.currentUser == null) {
      navigateTo("/my-account");
    }
    else if(cart.length === 0){
      navigateTo("/cart");

    }
    if (discount !== 0) {
      let data = {
        coupon_code: promoCode,
        phone_number: user.phone,
        "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
      };
      await axios.post(
        process.env.REACT_APP_BACKEND_URL+"/api/dashboard/coupons/used",
        data,
        {
          headers: {
            resturant: process.env.REACT_APP_DB_NAME,
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
          },
        }
      );
    }

    let today = new Date();
    let date =
      today.getDate() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    let time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + " " + time;

    await addToFirebase(
      "/Orders/" + localStorage.getItem("branch") + "/" + dateTime,
      dateTime
    );
    await addToFirebase(
      "/UserOrders/" +
        localStorage.getItem("branch") +
        "/" +
        user.id +
        "/" +
        dateTime,
      dateTime
    );

    deleteCart();
    navigateTo("/my-orders");
  };
  const addToFirebase = async (url, dateTime) => {
    await set(ref(db, url), {
      branch: branchName,
      address: deliveryAddress,
      client: JSON.parse(localStorage.getItem("user")),
      delivery: JSON.stringify(delivery),
      discount: discount,
      fees: service,
      list_of_product: Object.values(cart),
      message: "",
      order_id: dateTime,
      order_status: "تم ارسال طلبك",
      price: JSON.stringify(priceWithTax),
      reference_id: "cash",
      tax: (priceWithoutTax * (accounting.tax / 100)).toFixed(2),
      total_price: JSON.stringify(totalPrice),
    });
  };
  const deleteCart = () => {
    remove(ref(db, "/Cart/" + localStorage.getItem("branch") + "/" + user.id));
  };

  return (
    <div className="checkout-div text-white">
      <div className="w-50 mx-auto d-block mt-5">
        <div className="d-lg-flex justify-content-between align-items-center">
          <h2 className="text-primary-dark text-center">
            {t("receipt.receipt")}
          </h2>
          <img src={invoicePng} alt="" className="payment-imgs" />
        </div>
        <hr />
      </div>
      <div className=" d-flex justify-content-center align-items-center">
        {accounting && cart ? (
          <div className="reciept-div my-4 p-3 ">
            <form action="" className="my-2" onSubmit={handlePromoCode}>
              <label>
                {t("receipt.promocode")}
                <Form.Control
                  size="sm"
                  type="text"
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  placeholder="ex:Promocode22"
                  required
                />
              </label>
              <Button
                size="xs"
                type="submit"
                className="btn-primary-dark "
                loading={isPending ? true : false}
              >
                {t("receipt.check")}
              </Button>
            </form>
            {promoCodeMsg && <span>{promoCodeMsg}</span>}

            <div className="d-flex justify-content-between">
              <h5>{t("receipt.price")}:</h5>
              <h5>{priceWithoutTax}</h5>
            </div>
            <div className="d-flex justify-content-between">
              <h5>{t("receipt.discount")}:</h5>
              <h5 className="">-{discount}</h5>
            </div>
            <div className="d-flex justify-content-between">
              <h5>
                {t("receipt.tax")} {accounting.tax}%:
              </h5>
              <h5 className="">
                +{(priceWithoutTax * (accounting.tax / 100)).toFixed(2)}
              </h5>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <h5>{t("receipt.total")}:</h5>
              <h5>{priceWithTax}</h5>
            </div>

            <hr />
            <div className="d-flex justify-content-between">
              <h5>{t("receipt.delivery")}:</h5>
              <h5 className="">+{delivery}</h5>
            </div>

            <div className="d-flex justify-content-between">
              <h5>
                {t("receipt.service")} {accounting.fees_value}%:
              </h5>
              <h5 className="">+{service}</h5>
            </div>

            <Divider my="sm" variant="dashed" />
            <Divider my="sm" />

            <div className="d-flex justify-content-between">
              <h5>{t("receipt.total")}:</h5>
              <h5>
                {totalPrice} {t("products.jod")}
              </h5>
            </div>
            <div className="d-flex justify-content-end mt-3">
              <Button
                radius="xl"
                className="btn-primary-dark rounded-pill"
                onClick={handleSendOrder}
                disabled={cart.length === 0 ? true : ""}
              >
                {t("receipt.confirmorder")}{" "}
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center my-5 w-100"
            style={{ height: "100vh" }}
          >
            <Loader color="red" size="lg" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;

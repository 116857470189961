import { t } from "i18next";
import { useEffect, useState } from "react";
import useFirebaseGet from "../hooks/useFirebaseGet";
import CartImg from "../imgs/cart.png";
const CartIcon = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const { data: cart } = useFirebaseGet(
    "/Cart/" + localStorage.getItem("branch") + "/" + user.id
  );
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let cartTotal = 0;
    if (cart) {
      Object.values(cart).map((product) => {
        cartTotal += parseFloat(JSON.parse(product.total_price));
      });
    }
    setTotalPrice(cartTotal.toFixed(2));
  }, [cart]);

  return (
    <div className="cart-icon d-flex justify-content-between align-items-center">
       <h5 className="text-uppercase hide-for-mobile pt-3 mx-1">{totalPrice}\{t('navbar.cart')}</h5> 
      <div className="position-relative">
        <img src={CartImg} alt="" width={"30px"} 

       /> 
        <p className="position-absolute" style={{
            top:"60%",
            left:"50%",
            transform:"translate(-50%,-50%)"

        }}>{Object.values(cart).length}</p>
      </div>
    </div>
  );
};

export default CartIcon;

import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { User } from "tabler-icons-react";
import { useContext, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { AuthContext } from "../hooks/AuthContext";
import headerImg from "../imgs/navheader.png";
import SearchProducts from "./SearchProducts";
import CartIcon from "./CartIcon";
import CartIconNull from "./CartIconNull";
import NavbarMobile from "./NavbarMobile";
import { HashLink, NavHashLink } from "react-router-hash-link";


const Navbar = ({ links, bg, fontColor, btnStyle }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const { user, notAuthenticated } = useContext(AuthContext);
  const logout = (e) => {
    signOut(auth);
    localStorage.setItem("user", null);
    notAuthenticated();
  };
  return (
    <div className="navbar-div">
      <div className="header-section hide-for-mobile">
        <img src={headerImg} alt="" width={"100%"} />
        <div className="buttons-div-navbar d-flex justify-content-end ">
          <>
            {!user && (
              <Link
                className={`mx-2  btn-primary-dark  text-decoration-none`}
                to={"/my-account"}
              >
                <User size={22} strokeWidth={1} /> {t("navbar.myaccount")}
              </Link>
            )}
            {user && (
              <Dropdown className={""}>
                <Dropdown.Toggle
                  className={` mx-2 btn-primary-dark `}
                  id="dropdown-basic"
                >
                  <User size={22} strokeWidth={2} /> {user.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="text-decoration-none "
                    onClick={logout}
                  >
                    {t("register.signout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
          <span
            className={`langbtn  mx-2 mt-1`}
            onClick={() => changeLanguage(t("navbar.langlink"))}
          >
            {t("navbar.langlink")}
            <img
              className="mx-1"
              src={require("../imgs/" + i18n.language + ".png")}
              alt=""
              width="20px"
            />
          </span>
        </div>
      </div>
      <div className="navbar-links-section hide-for-mobile">
        <div className="container d-flex justify-content-between pb-2 border-bottom border-dark">
          <div className="navbar-links">
            {links.map((link) => (
              <Link
                key={link.id}
                className={`mx-3 mt-1 text-primary-dark font-size-20 ${
                  location.pathname === link.link
                    ? "border-bottom border-dark "
                    : ""
                } text-decoration-none`}
                to={link.link}
              >
                {link.icon} {t("navbar." + link.title)}{" "}
              </Link>
            ))}
            <span className="font-size-20 curser-pointer text-primary-dark " onClick={(el)=>window.scrollTo(0, document.body.scrollHeight)}>{t('navbar.contact')}</span>

          </div>

          <div className="search-cart-section d-flex justify-content-between">
            <SearchProducts />
            <Link
              className="text-decoration-none text-primary-dark"
              to={"/cart"}
            >
              {user ? <CartIcon /> : <CartIconNull />}
            </Link>
          </div>
        </div>
      </div>
    <NavbarMobile links={links} />
    </div>
  );
};

export default Navbar;

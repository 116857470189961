import { useRef, useState } from "react";
import React from "react";
import { Carousel } from "@mantine/carousel";
import Autoplay from "embla-carousel-autoplay";
import { t } from "i18next";
import Products from "./Products";

const SubCategories = ({ categories }) => {
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const [productsRoute, setProductsRoute] = useState(`categories/products/${categories[0].id}/`);
  const [productsHeader, setProductsHeader] = useState(categories[0].name);

  // const [searchValue, setSearchValue] = useState("");
  const getProducts = (e,route,header) => {

    e.preventDefault();
    setProductsHeader(header)
    setProductsRoute(route);
  };

  return (
    <div className="subcategories-section container mt-4 ">
      {categories.length !== 0 ? (
        <Carousel 
          slideSize="20%"
          slideGap="md"
          breakpoints={[
            { maxWidth: "md", slideSize: "50%" },
            { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
          ]}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          align="start"
          dir="ltr"
          withIndicators
        >
          {categories.map((category, index) => (
            <Carousel.Slide
              key={category.id}
              id={category.id}
              onClick={(e) =>
                getProducts(e,`categories/products/${category.id}/`,category.name)
              }
            >
              <div className="subcategory-card  ">
                <div className="subcategory-card-img-div ">
                  <img
                    src={category.photo}
                    className="subcategory-card-img "
                    alt=""
                  />
                </div>

                <h5 className="text-center text-primary-dark py-2 text-capitalize">
                  {category.name}
                </h5>
              </div>
            </Carousel.Slide>
          ))}
        </Carousel>
      ) : (
        <p>{t("msgs.nocategories")}</p>
      )}

      {/* <div className="search-div my-4" dir="ltr" id="search">
        <form
          action=""
          className="d-flex justify-content-center"
          onSubmit={(e) => getProducts(e,`products/search/name=${searchValue}/page=`,searchValue)}
        >
          <input
            type="text"
            name=""
            className="form-control w-lg-25  w-sm-50 search-bar px-5 py-2 rounded-0 rounded-left"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            required
          />
          <input
            type={"submit"}
            value={t("form.search")}
            className={" search-btn rounded-0 rounded-end "}
          />
        </form>
      </div> */}
      {productsRoute && <Products route={productsRoute} page={"0"} header={productsHeader}/>}
    </div>
  );
};

export default SubCategories;

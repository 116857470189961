import useFetch from "../hooks/useFetch";
import Categories from "../components/Categories";
import { Loader } from "@mantine/core";

const Products= () => {
    const { data: categories } = useFetch("categories");

    return ( 

<div className="products-page-section mt-5">





{categories ? <Categories categories={categories} />

:
<div className="d-flex justify-content-center my-5 w-100" style={{height:"100vh"}}>
          <Loader color="red" size="lg" />
        </div>

}

</div>



     );
}
 
export default Products
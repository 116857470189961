import useFetch from "../hooks/useFetch";
import useFirebaseGet from "../hooks/useFirebaseGet";
import { useTranslation } from "react-i18next";
import {
  Phone,
  BrandFacebook,
  BrandTwitter,
  BrandInstagram,
  MapPin,
  Mail,
  BrandWhatsapp,
} from "tabler-icons-react";
import { ThemeIcon } from "@mantine/core";

const Footer = () => {
  const { data: socialMedia } = useFetch("socials");
  const { data: branches } = useFirebaseGet("/branches");
  const { t, i18n } = useTranslation();

  return (
    <div className="footer-div bg-pink text-white py-2" id="footer">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-3 col-md-6 col-12 d-flex align-items-center">
            <h2 className="">Baloonz Up</h2>
          </div>

          {branches && (
            <div className="col-lg-3 col-md-6 col-12">
              <h3 className="mt-4">{t('footer.branches')}</h3>
              {Object.values(branches).map((branch) => (
                <div key={branch.id}>
                  <a className="text-decoration-none text-white" target={'_blank'} href={`http://maps.google.com/?q=${branch["lat"]},${branch["lng"]}`}>
                    <MapPin size={22} strokeWidth={1}  color={"white"} />{" "}
                   
                    {branch["address_" + i18n.language]}
                  </a>
                  </div>
              ))}
            </div>
          )}
          {socialMedia && (
            <>
              <div className="col-lg-3 col-md-6 col-12">
                <h3 className="mt-4">{t('footer.contactus')}</h3>
                {socialMedia.phone_1 && (
                  <h6>
                    <Phone size={22} strokeWidth={1} color={"white"} />{" "}
                   <a className="text-decoration-none text-white" href={`tel:${socialMedia.phone_1}`}>{socialMedia.phone_1}</a> 
                  </h6>
                )}
                {socialMedia.phone_2 && (
                  <h6>
                    <Phone size={22} strokeWidth={1} color={"white"} />{" "}
                    <a className="text-decoration-none text-white" href={`tel:${socialMedia.phone_2}`}>{socialMedia.phone_2}</a> 
                  </h6>
                )}
                {socialMedia.phone_3 && (
                  <h6>
                    <Phone size={22} strokeWidth={1} color={"white"} />{" "}
                    <a className="text-decoration-none text-white" href={`tel:${socialMedia.phone_3}`}>{socialMedia.phone_3}</a> 
                  </h6>
                )}
 {socialMedia.email && (
                  <h6>
                    <Mail size={22} strokeWidth={1} color={"white"} />{" "}
                    <a className="text-decoration-none text-white" href={`mailto:${socialMedia.email}`}>{socialMedia.email}</a> 
                  </h6>
                )}


              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <h3 className="mt-4">{t('footer.followus')}</h3>
                {socialMedia.facebook && (
                  <a href={socialMedia.facebook} target="_blank" rel="noreferrer">
                    <ThemeIcon radius="xl" size="lg" color="#404bbf">
                      {" "}
                      <BrandFacebook
                        size={22}
                        strokeWidth={1}
                        color={"white"}
                      />{" "}
                    </ThemeIcon>
                  </a>
                )}{" "}
                {socialMedia.twitter && (
                  <a href={socialMedia.twitter} target="_blank" rel="noreferrer">
                    <ThemeIcon radius="xl" size="lg" color="cyan">
                      {" "}
                      <BrandTwitter
                        size={22}
                        strokeWidth={1}
                        color={"white"}
                      />{" "}
                    </ThemeIcon>
                  </a>
                )}{" "}
                {socialMedia.instagram && (
                  <a href={socialMedia.instagram} target="_blank" rel="noreferrer">
                    <ThemeIcon
                      radius="md"
                      size="lg"
                      variant="gradient"
                      gradient={{ from: "orange", to: "purple" }}
                    >
                      {" "}
                      <BrandInstagram
                        size={22}
                        strokeWidth={1}
                        color={"white"}
                      />{" "}
                    </ThemeIcon>
                  </a>
                )}{" "}
                 {socialMedia.whatsapp && (
                  <a href={`https://api.whatsapp.com/send?phone=${socialMedia.whatsapp}`} target="_blank" rel="noreferrer">
                    <ThemeIcon
                      radius="md"
                      size="lg"
                      color="green"
                    >
                      {" "}
                      <BrandWhatsapp
                        size={22}
                        strokeWidth={1}
                        color={"white"}
                      />{" "}
                    </ThemeIcon>
                  </a>
                )}
              </div>
            </>
          )}

        </div>
      </div>
    </div>
  );
};

export default Footer;

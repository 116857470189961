// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getDatabase} from 'firebase/database';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "balloonzup-541cc.firebaseapp.com",
  databaseURL: "https://balloonzup-541cc-default-rtdb.firebaseio.com",
  projectId: "balloonzup-541cc",
  storageBucket: "balloonzup-541cc.appspot.com",
  messagingSenderId: "753422497532",
  appId: "1:753422497532:web:e3622674a252143f49c979",
  measurementId: "G-72ZVLL8P4G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db=getDatabase(app);
const auth=getAuth(app);
export {db , auth }
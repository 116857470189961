import { Link, useLocation } from "react-router-dom";
import { Button, Collapse } from "@mantine/core";
import { Menu2 } from "tabler-icons-react";
import { ActionIcon } from "@mantine/core";
import headerImg from "../imgs/navheader.png";
import SearchProducts from "./SearchProducts";
import { useTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";
import { User } from "tabler-icons-react";
import { Search } from "tabler-icons-react";

import CartIcon from "./CartIcon";
import CartIconNull from "./CartIconNull";
import { AuthContext } from "../hooks/AuthContext";
import { useContext, useState } from "react";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
import { X } from 'tabler-icons-react';

const NavbarMobile = ({links}) => {
    const [opened, setOpened] = useState(false);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
      };
      const { user, notAuthenticated } = useContext(AuthContext);
      const logout = (e) => {
        signOut(auth);
        localStorage.setItem("user", null);
        notAuthenticated();
      };

    return (
      <div className="mobile-navbar hide-for-desktop">
        <img src={headerImg} alt="" width={"100%"} />

        <div
          className="container d-flex justify-content-between align-items-center"
          style={{
            position: "absolute",
            top: "3vh",
          }}
        >
          <ActionIcon
            color="dark"
            radius="xl"
            variant="filled"
            className="rounded-circle"
            onClick={() => setOpened((o) => !o)}
          >
            {opened ?
            <X size={22} strokeWidth={2} />:
            <Menu2 size={22} strokeWidth={2} />}
          </ActionIcon>
          <div className="d-flex justify-content-between align-items-center">
         
          <Link  className={`text-decoration-none mx-2 text-primary-dark ${
                    location.pathname === '/search-page'
                      ? "d-none "
                      : ""
                  } `} to={"/search-page"}>
        <Search size={24} strokeWidth={2} color={"black"} />
        </Link>
    

          <Link className="text-decoration-none text-primary-dark" to={"/cart"}>
            {user ? <CartIcon /> : <CartIconNull />}
          </Link>
          </div>
        </div>
        <div className="container menu-container">
          <Collapse in={opened}>
            {links.map((link) => (
              <div key={link.id}>
                <Link
                  
                  className={`mx-3 mt-1 text-primary-dark font-size-20 ${
                    location.pathname === link.link
                      ? "border-bottom border-dark "
                      : ""
                  } text-decoration-none`}
                  to={link.link}
                >
                  {link.icon} {t("navbar." + link.title)}{" "}
                </Link>
                <br />
              </div>
            ))}
                        <span className="mx-3 font-size-20 curser-pointer text-primary-dark" onClick={(el)=>window.scrollTo(0, document.body.scrollHeight)}>{t('navbar.contact')}</span>
<br />
            {!user && (
              <Link
                className={`mx-2  btn-primary-dark  text-decoration-none`}
                to={"/my-account"}
              >
                <User size={22} strokeWidth={1} /> {t("navbar.myaccount")}
              </Link>
            )}
            {user && (
              <Dropdown className={""}>
                <Dropdown.Toggle
                  className={` mx-2 btn-primary-dark `}
                  id="dropdown-basic"
                >
                  <User size={22} strokeWidth={2} /> {user.name}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    className="text-decoration-none "
                    onClick={logout}
                  >
                    {t("register.signout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
<br />
            <span
              className={`langbtn  mx-2 mt-1`}
              onClick={() => changeLanguage(t("navbar.langlink"))}
            >
              {t("navbar.langlink")}
              <img
                className="mx-1"
                src={require("../imgs/" + i18n.language + ".png")}
                alt=""
                width="20px"
              />
            </span>
          </Collapse>
        </div>
      </div>
    );
}
 
export default NavbarMobile;
import useFetch from "../hooks/useFetch";
import Categories from "../components/Categories";
// import { Carousel } from "@mantine/carousel";
import { Loader } from "@mantine/core";
import Carousel from "react-bootstrap/Carousel";

const Home = () => {

  const { data: categories } = useFetch("categories");
  const { data: ads } = useFetch("ads");
  return (
    <div className="home mt-3">
      <div className=" mb-4  home-1st-section  d-lg-flex justify-content-between align-items-center ">
        {ads ? (
          <Carousel className="w-100">
            {Object.values(ads).map((ad) => (
              <Carousel.Item key={ad.id}>
                <img src={ad.ads} alt="" className="ads-slider-img" />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : (
          <div
            className="d-flex justify-content-center align-items-center my-5 w-100"
            style={{ height: "60vh" }}
          >
            <Loader color="red" size="lg" />
          </div>
        )}
      </div>
      {categories && <Categories categories={categories} />}
    </div>
  );
};

export default Home;
